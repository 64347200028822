<template>
  <v-card>
    <!-- <v-card-title class="justify-center">
      <span class="text-h5"><strong>TISSUE DETAILS</strong></span>
    </v-card-title> -->
    <v-card-title class="justify-start">
      <span class="font-weight-semibold">Donor Information</span>
    </v-card-title>
    <v-row class="ml-3 mr-5 pt-3 mb-3">
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Donor ID:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="TissueData.ebsr_donor_id" dense hide-details readonly></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Primary COD:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="TissueData.primary_COD" dense hide-details readonly></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Tissue ID*:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="TissueData.tissue_id" dense hide-details readonly :error="errr === false"></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Ocular Cooling:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="TissueData.ocular_cooling" dense hide-details readonly></v-text-field>
      </v-col>



      <v-col cols="12" md="2" v-if="TissueData.date_time_body_subjected_refrig != ''">
        <p class="body-2 mt-2 mb-0">Date-Time Body Subjected to Refrigeration:</p>
      </v-col>
      <v-col cols="12" md="4" v-if="TissueData.date_time_body_subjected_refrig != ''">
        <v-text-field v-model="date_time_body_subjected_refrig_modal" dense hide-details readonly></v-text-field>
      </v-col>

      <v-col cols="12" md="2" v-if="TissueData.date_time_body_removed_refrig != ''">
        <p class="body-2 mt-2 mb-0">Date-Time Body Removed from Refrigeration:</p>
      </v-col>
      <v-col cols="12" md="4" v-if="TissueData.date_time_body_removed_refrig != ''">
        <v-text-field v-model="date_time_body_removed_refrig_modal" dense hide-details readonly></v-text-field>
      </v-col>


      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Tissue Type*:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="TissueData.tissue_type" dense hide-details :readonly="readOnlyField1 === true"
          :error="errr1 === false"></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Total:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="TissueData.total" dense hide-details readonly></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Donor Age:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="TissueData.age" dense hide-details readonly></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Excision Tech:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="TissueData.excision_tech" dense hide-details readonly></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Donor Gender:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="TissueData.gender" dense hide-details readonly></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Preservation Tech:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="TissueData.preservation_tech" dense hide-details readonly></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Date-Time of Death:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="dateOfTimeOfDeath" dense hide-details readonly></v-text-field>
        <!-- <v-datetime-picker v-model="dateOfTimeOfDeath" time-format="HH:mm:ss" :text-field-props="textFieldProps"></v-datetime-picker> -->
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Storage Media:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="TissueData.storage_media" dense hide-details readonly></v-text-field>
      </v-col>

      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Date-Time of in situ:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="dateTimeOfSitu" dense hide-details readonly></v-text-field>
        <!-- <v-datetime-picker v-model="dateTimeOfSitu" time-format="HH:mm:ss" :text-field-props="textFieldProps" :readonly="readOnlyField1 === true"></v-datetime-picker> -->
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Media Lot#:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="TissueData.media_lot" dense hide-details readonly></v-text-field>
      </v-col>

      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Date-Time of preservation:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="dateTimeOfSitu" dense hide-details readonly></v-text-field>
        <!-- <v-datetime-picker v-model="dateTimeOfSitu" time-format="HH:mm:ss"  :readonly="readOnlyField1 === true"></v-datetime-picker> -->
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Tissue Expiry Date-Time*:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="TissueExpiryDateTime" dense hide-details readonly></v-text-field>
        <!-- <v-datetime-picker v-model="TissueExpiryDateTime" time-format="HH:mm:ss"  :readonly="readOnlyField1 === true" :error="errr3 === false"></v-datetime-picker> -->
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Death to preservation Time( in Hrs)*:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="TissueData.death_to_preservation_time" dense hide-details readonly
          :error="errr2 === false"></v-text-field>
        <!-- <v-datetime-picker v-model="dateTimeOfPreservation" time-format="HH:mm:ss" :text-field-props="textFieldProps" ></v-datetime-picker> -->
      </v-col>
    </v-row>


    <v-card-title class="justify-start">
      <span class="font-weight-semibold">Medical Summary</span>
    </v-card-title>
    <v-row class="ml-3 mr-5 pt-3 mb-3">
      <v-col cols="12" md="4">
        <p class="body-2 mt-3 mb-0">Social and Behavioral History:</p>
      </v-col>
      <v-col cols="12" md="8">
        <v-textarea v-model="TissueData.social_behavioral_hx" hide-details row-height="15" rows="2" class="pt-0 mt-0"
          readonly></v-textarea>
      </v-col>
      <v-col cols="12" md="4">
        <p class="body-2 mt-3 mb-0">Medical/Surgical History:</p>
      </v-col>
      <v-col cols="12" md="8">
        <v-textarea v-model="TissueData.medical_history" hide-details row-height="15" rows="2" class="pt-0 mt-0"
          readonly></v-textarea>
      </v-col>
      <v-col cols="12" md="3">
        <p class="body-2 mt-1 mb-0">Donor on a Respirator:</p>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="TissueData.donor_on_respirator_vantilator" dense hide-details readonly></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <p class="body-2 mt-1 mb-0">Amount of Time on a Respirator:</p>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="TissueData.amount_of_time_on_a_respirator" dense hide-details readonly></v-text-field>
      </v-col>
    </v-row>


    <v-card-title class="justify-start">
      <span class="font-weight-semibold">Pathology</span>
    </v-card-title>
    <v-row class="ml-3 mr-5 pt-3 mb-3">
      <v-col cols="12" md="3">
        <p class="body-2 mt-1 mb-0">Autopsy:</p>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="TissueData.autospy" dense hide-details readonly></v-text-field>
      </v-col>
    </v-row>


    <v-card-title class="justify-start">
      <span class="font-weight-semibold">Cornea Evaluation</span>
    </v-card-title>
    <v-row class="ml-3 mr-5 pt-3 mb-3">
      <v-col cols="12" md="2">
        <p class="body-2 mt-1 mb-0">Approved Usages:</p>
      </v-col>
      <v-col cols="12" md="10">
        <v-text-field v-model="TissueData.approved_usages" dense hide-details readonly></v-text-field>
        <!-- <p>{{valuee}}</p> -->
        <!-- <p>{{myitem}}</p> -->
      </v-col>
      <v-col cols="12" md="3">
        <p class="body-2 mt-1 mb-0">Clear Zone (mm in Diameter):</p>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="TissueData.clear_zone" dense hide-details readonly></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-1 mb-0">Lens Type:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="TissueData.lens_type" dense hide-details readonly></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-1 mb-0">Cell Count (Per mm):</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="TissueData.cell_count" dense hide-details readonly></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <p class="body-2 mt-1 mb-0">Pachymetry(Microns):</p>
      </v-col>
      <v-col cols="12" md="3">
        <!-- <v-text-field v-model="TissueData.pachymetry" dense hide-details :readonly="readOnlyField1 === true" :error="errr4 === false"></v-text-field> -->
        <v-text-field v-model="TissueData.pachymetry" dense hide-details readonly :error="errr4 === false"></v-text-field>
      </v-col>
    </v-row>
    <v-row class="ml-3 mr-5 pt-3 mb-3">
      <v-col cols="12" md="2">
        <p class="body-2 mt-1 mb-0">Epthelium:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="TissueData.epithelium_clear_and_intact" label="Clear and intact" readonly></v-text-field>
        <v-text-field v-model="TissueData.heze" v-if="TissueData.epithelium_clear_and_intact == 'No'" label="Haze"
          readonly></v-text-field>
        <v-text-field v-model="TissueData.exposure" v-if="TissueData.epithelium_clear_and_intact == 'No'" label="Exposure"
          readonly></v-text-field>
        <v-text-field v-model="TissueData.exposure_location" v-if="TissueData.epithelium_clear_and_intact == 'No'"
          label="Exposure Location" readonly></v-text-field>
        <v-text-field v-model="TissueData.sloughing_location" v-if="TissueData.epithelium_clear_and_intact == 'No'"
          label="Sloughing location" readonly></v-text-field>
        <v-text-field v-model="TissueData.sloughing_area" v-if="TissueData.epithelium_clear_and_intact == 'No'"
          label="Sloughing area" readonly></v-text-field>
        <v-text-field v-model="TissueData.debris" v-if="TissueData.epithelium_clear_and_intact == 'No'" label="Debris"
          readonly></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <!-- <v-file-input  
          v-model="uploadKonasKssImg"
          chips 
          accept="image/*" 
          label="Konan KSS" 
          @change="onFileChange"
          :readonly="readOnlyField1 === true" 
        /> -->
      </v-col>
      <v-col v-if="!uploadKonasKssImgObj && TissueData.upload_konas_kss_img" cols="3">
        <p>Konan KSS</p>
        <img v-img style="width:78%"
          :src="!uploadKonasKssImgObj ? `https://api.stage.ebsr.in/${TissueData.upload_konas_kss_img}` : ''" />
      </v-col>
      <v-col v-if="!!uploadKonasKssImgObj" cols="3">
        <img v-img style="width: 78%;" :src="uploadKonasKssImgObj ? uploadKonasKssImgObj : ''" />
      </v-col>
    </v-row>
    <!-- <v-col cols="12" md="3">
        <img v-img style="width:100%" :src="`https://api.stage.ebsr.in/${TissueData.upload_konas_kss_img}`" />
      </v-col> -->
    <v-row class="ml-3 mr-5 pt-3 mb-3">
      <v-col cols="12" md="2">
        <p class="body-2 mt-1 mb-0">Stroma:</p>
      </v-col>
      <v-col cols="12 " md="4">
        <v-text-field v-model="TissueData.stroma_clear_and_intact" label="Clear and Compact"
          :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      <v-col cols="12" md="1"></v-col>
      <v-col cols="12 " md="4">
        <v-text-field v-model="TissueData.edema" label="Edema" :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      <v-col cols="12" md="2"> </v-col>
      <v-col cols="12 " md="4">
        <v-text-field v-model="TissueData.stroma_arcus" label="Arcus" :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      <v-col cols="12" md="1"></v-col>
      <v-col cols="12 " md="4">
        <v-text-field v-model="TissueData.striae" label="Striae" :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      <v-col cols="12" md="2"> </v-col>
      <v-col cols="12 " md="4">
        <v-text-field v-model="TissueData.stroma_opacities1" label="Opacities 1"
          :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      <v-col cols="12" md="2"> </v-col>
      <v-col cols="12 " md="4">
        <v-text-field v-model="TissueData.stroma_opacities2" label="Opacities 2"
          :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-1 mb-0">Descemet's:</p>
      </v-col>
      <v-col cols="12 " md="4">
        <v-text-field v-model="TissueData.descemets_folds" label="Folds"
          :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      <v-col cols="12 " md="6"></v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-1 mb-0">Endothelium:</p>
      </v-col>
      <v-col cols="12 " md="4">
        <v-text-field v-model="TissueData.endothelium_stress_lines" label="Stress Line"
          :readonly="readOnlyField1 === true"></v-text-field>
        <v-text-field v-model="TissueData.endothelium_stress_lines_location"
          v-if="TissueData.endothelium_stress_lines == 'Yes'" label="Stress Location"
          :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      <v-col cols="12" md="1"></v-col>
      <v-col cols="12 " md="4">
        <v-text-field v-model="TissueData.endothelium_cell_dropoult" label="Cell Dropoult"
          :readonly="readOnlyField1 === true"></v-text-field>
        <v-text-field v-model="TissueData.endothelium_cell_dropoult_location"
          v-if="TissueData.endothelium_cell_dropoult != 'None'" label="Location"
          :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      <v-col cols="12" md="2"></v-col>
      <v-col cols="12 " md="4">
        <v-text-field v-model="TissueData.endothelium_polymegathism" label="Polymegathism"
          :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      <v-col cols="12" md="1"></v-col>
      <v-col cols="12 " md="4">
        <v-text-field v-model="TissueData.endothelium_pleomorphism" label="Pleomorphism"
          :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-1 mb-0">Additional:</p>
      </v-col>
      <v-col cols="12" md="10">
        <p class="body-2">Jaundice: {{ TissueData.add_info_jaundice }}; Lens: {{ TissueData.add_info_lens }}; Scleral rim
          evalution: {{ TissueData.add_info_scleral_rim_evalution }};</p>
        <!-- <v-text-field v-model="TissueData.add_info_jaundice" dense hide-details></v-text-field> -->
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-1 mb-0">Comments:</p>
      </v-col>
      <v-col cols="12" md="10">
        <v-textarea v-model="TissueData.comment" hide-details row-height="15" rows="2" class="pt-0 mt-0"
          :readonly="readOnlyField1 === true"></v-textarea>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Slit Lamp Date-Time:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field readonly v-model="siteLampDate"></v-text-field>
        <!-- <v-datetime-picker v-model="siteLampDate" time-format="HH:mm:ss" :text-field-props="textFieldProps" :readonly="readOnlyField1 === true"></v-datetime-picker> -->
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Slit Lamp Tech:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="TissueData.site_lamp_tech" dense hide-details readonly></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Specular Date-Time:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="specularDateTime" :text-field-props="textFieldProps" readonly></v-text-field>
        <!-- <v-datetime-picker v-model="specularDateTime" time-format="HH:mm:ss" :text-field-props="textFieldProps" :readonly="readOnlyField1 === true" :error="errr5 === false"></v-datetime-picker> -->
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Specular Tech:</p>
      </v-col>
      <v-col cols="12" md="4">
        <!-- <v-text-field v-model="TissueData.specular_tech" dense hide-details :readonly="readOnlyField1 === true" :error="errr6 === false"></v-text-field> -->
        <v-text-field v-model="TissueData.specular_tech" dense hide-details readonly
          :error="errr6 === false"></v-text-field>
      </v-col>
    </v-row>

    <v-card-title class="justify-start">
      <span class="font-weight-semibold">Serology</span>
    </v-card-title>
    <v-row class="ml-3 mr-5 pt-3 mb-3">
      <v-col cols="12" md="3">
        <!-- <v-text-field label="HBsAG*" v-model="TissueData.HBsAg" dense hide-details :readonly="readOnlyField1 === true" :error="errr7 === false"></v-text-field> -->

        <v-autocomplete v-model="TissueData.HBsAg" label="HBsAG*" required :items="HBsAgOption"
          @change="HBsAgSelect(TissueData.HBsAg)" :error="errr7 === false"></v-autocomplete>

      </v-col>
      <v-col cols="12" md="3">
        <!-- <v-text-field label="HCV" v-model="TissueData.history_of_hcv" dense hide-details
          :error="errr14 === false"></v-text-field> -->

        <v-autocomplete v-model="TissueData.history_of_hcv" label="HCV" :items="HBsAgOption"
          @change="HcvSelect(TissueData.history_of_hcv)"></v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">

        <!-- <v-text-field label="HIV 1/2*" v-model="TissueData.hiv_I_II" dense hide-details
          :error="errr8 === false"></v-text-field> -->

        <v-autocomplete v-model="TissueData.hiv_I_II" label="HIV 1/2" :items="HBsAgOption"
          @change="hiv_I_II_Select(TissueData.hiv_I_II)"></v-autocomplete>

      </v-col>
      <v-col cols="12" md="3">
        <!-- <v-text-field label="syphilis*" v-model="TissueData.Syphilis" dense hide-details
          :readonly="readOnlyField1 === true" :error="errr9 === false"></v-text-field> -->

        <v-autocomplete v-model="TissueData.Syphilis" label="Syphilis*" :items="HBsAgOption"
          @change="SyphilisSelect(TissueData.Syphilis)" :error="errr9 === false"></v-autocomplete>

      </v-col>
      <v-col cols="12" md="6">
        <p class="body-2 mt-2 mb-0">This tissue was reviewed and released for Transplant based on the following*:</p>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="TissueData.review_and_released_for_transplant_based_on" dense hide-details
          :readonly="readOnlyField1 === true" :error="errr10 === false"></v-text-field>
        <!-- <v-radio-group row class="mt-0">
          <v-radio  label="physical Assessment" value="Yes"></v-radio>
          <v-radio name="No" label="Donor Family" value="No"></v-radio>
          <v-radio name="No" label="Attending Physicion" value="No"></v-radio>
        </v-radio-group> -->
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Final Release By*:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="TissueData.final_release_by" dense hide-details :readonly="readOnlyField1 === true"
          :error="errr11 === false"></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Final Release Date-Time*:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-datetime-picker v-model="finalReleaseDateTime" date-format="dd-MM-yyyy" time-format="HH:mm"
          :text-field-props="textFieldProps" :readonly="readOnlyField1 === true"
          :error="errr12 === false"></v-datetime-picker>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Registered With HOTA*</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="TissueData.registered_with_HOTA" dense hide-details :readonly="readOnlyField1 === true"
          :error="errr13 === false"></v-text-field>
      </v-col>
    </v-row>
    <!-- <v-card-title class="justify-start">
      <span class="font-weight-semibold">Tissue Disposition</span>
    </v-card-title>
     <v-card-title class="justify-center">
      <span class="font-weight-semibold">Summary</span>
    </v-card-title>
     <v-row class="ml-3 mr-5 pt-3 mb-3">
         <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Tissue #</p>
        <v-text-field v-model="TissueData.tissue_id" hide-details readonly></v-text-field>
      </v-col> 
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Intent</p>
        <v-text-field v-model="TissueData.intent" hide-details readonly></v-text-field>
      </v-col>
       <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Outcome</p>
        <v-text-field v-model="TissueData.tissue_disposition_outcome"  hide-details></v-text-field>
      </v-col>
       <v-col cols="12" md="3">
        <p class="body-2 mt-2 mb-0">Sub OutCome</p>
        <v-text-field v-model="TissueData.tissue_disposition_sub_outcome"   hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <p class="body-2 mt-2 mb-0">Reason(s) Not Released or Transplant</p>
        <v-text-field v-model="TissueData.reason_not_released_or_transplanted"  dense hide-details></v-text-field>
      </v-col>
     </v-row> -->

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" class="mb-5" v-if="saveBtn" @click="same()"> Save </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import donorService from '@/service/Donor.service'
import TissueService from "@/service/Tissue.service"
import moment from "moment";
import axios from "axios";
const baseUrl = "https://api.stage.ebsr.in/";
const localData = JSON.parse(localStorage.getItem("token"));
const token = localData.token;

export default {

  data() {
    return {
      errr: true,
      errr1: true,
      errr2: true,
      errr3: true,
      errr4: true,
      errr5: true,
      errr6: true,
      errr7: true,
      errr8: true,
      errr9: true,
      errr10: true,
      errr11: true,
      errr12: true,
      errr13: true,
      errr14: true,

      HBsAgOption: ['Positive', 'Negative'],
      formtype: '',
      readOnlyField1: false,
      saveBtn: true,
      TissueData: {
        date_of_time_of_death: '',
        date_time_of_in_situ: '',
        death_to_preservation_time: '',
        tissue_id: '',
        ebsr_donor_id: '',
        tissue_type: '',
        ocular_cooling: '',
        total: '',
        tissue_expiry_date: '',
        upload_konas_kss_img: '',
        pachymetry: '',
        specular_date_time2: '',
        specular_tech: '',
        HBsAg: '',
        Syphilis: '',
        review_and_released_for_transplant_based_on: '',
        final_release_by: '',
        final_release_date_time: '',
        registered_with_HOTA: '',
        tissue_disposition_outcome: '',
        tissue_disposition_sub_outcome: '',
        reason_not_released_or_transplanted: '',
        approved_usages: '',
        site_lamp_tech: '',
        hiv_I_II: '',
        history_of_hcv: '',
      },
      uploadKonasKssImg: null,
      image: null,
      listLoading: false,
      timeout: 2000,
      snackbar: false,
      snackbarText: '',
      // epithelium_clear_and_intact:'No',
      endothelium_stress_lines: 'No',
      Cell_Dropoult: 'None',
      textFieldProps: {
        dense: 'true',
        "hide-details": 'true',

      }

    }
  },
  computed: {
    dateOfTimeOfDeath: {
      get() {
        return moment.utc(this.TissueData.date_of_time_of_death).format(
          "DD-MM-YYYY HH:mm"
        );
      },
    },

    date_time_body_subjected_refrig_modal: {
      get() {
        return moment.utc(this.TissueData.date_time_body_subjected_refrig).format(
          "DD-MM-YYYY HH:mm"
        );
      },
    },

    date_time_body_removed_refrig_modal: {
      get() {
        return moment.utc(this.TissueData.date_time_body_removed_refrig).format(
          "DD-MM-YYYY HH:mm"
        );
      },
    },

    dateTimeOfSitu: {
      get() {
        return moment.utc(this.TissueData.date_time_of_in_situ).format(
          "DD-MM-YYYY HH:mm"
        );
      },
      // set(value) {
      //   this.$set(this.TissueData, "date_time_of_in_situ", value);
      // },
    },


    dateTimeOfPreservation: {
      get() {
        return moment.utc(this.TissueData.death_to_preservation_time).format(
          "DD-MM-YYYY HH:mm"
        );
      },
      set(value) {
        this.$set(this.TissueData, "death_to_preservation_time", moment(value).format(
          "YYYY-MM-DD HH:mm"
        ));
      },
    },

    TissueExpiryDateTime: {
      get() {
        return moment.utc(this.TissueData.tissue_expiry_date).format(
          "DD-MM-YYYY HH:mm"
        );
      },
      set(value) {
        this.$set(this.TissueData, "tissue_expiry_date", moment(value).format(
          "YYYY-MM-DD HH:mm"
        ));
      },
    },

    siteLampDate: {
      get() {
        console.log("aaaaaaaaaaaa ", this.TissueData.site_lamp_date)

        if (this.TissueData.site_lamp_date) {
          return moment.utc(this.TissueData.site_lamp_date).format(
            "DD-MM-YYYY HH:mm"
          );
        }

      },
      // set(value) {
      //   this.$set(this.TissueData, "site_lamp_date", moment(value).utcOffset("+5:30").format(
      //     "YYYY-MM-DD HH:mm:ss"
      //   ));
      // },
    },
    specularDateTime: {
      get() {
        if (this.TissueData.specular_date_time2) {
          return moment.utc(this.TissueData.specular_date_time2).format(
            "DD-MM-YYYY HH:mm"
          );
        }
      },
      // set(value) {
      //   this.$set(this.TissueData, "specular_date_time2", moment(value).format(
      //     "YYYY-MM-DD HH:mm"
      //   ));
      // },
    },

    finalReleaseDateTime: {
      get() {
        return moment.utc(this.TissueData.final_release_date_time).format(
          "DD-MM-YYYY HH:mm"
        );
      },
      set(value) {
        this.$set(this.TissueData, "final_release_date_time", moment(value).format(
          "YYYY-MM-DD HH:mm"
        ));
      },
    },

  },

  watch: {
    options: {
      handler() {
        this.Tissue_Detail()
        this.getdonorList()
      },
      deep: true,
    },
  },

  async mounted() {
    this.Tissue_Detail()
    this.getdonorList()
  },
  methods: {
    same() {
      if (this.TissueData.tissue_id == "" || this.TissueData.tissue_id == null) {
        this.errr = false
        return false,
          this.snackbarText = "Please Fill the Tissue Id",
          this.snackbar = true
      }
      // else if(this.TissueData.ocular_cooling == "" || this.TissueData.ocular_cooling == null){
      //   return false,
      //   this.snackbarText = "Please Fill the ocular cooling",
      //   this.snackbar  =true
      // }
      else if (this.TissueData.tissue_type == "" || this.TissueData.tissue_type == null) {
        this.errr1 = false
        return false,
          this.snackbarText = "Please Fill the Tissue type",
          this.snackbar = true
      }
      // else if(this.TissueData.total == "" || this.TissueData.total == null){
      //   return false,
      //   this.snackbarText = "Please Fill the total",
      //   this.snackbar  =true
      // }

      else if (this.TissueData.death_to_preservation_time == "" || this.TissueData.death_to_preservation_time == null) {
        this.errr2 = false
        return false,
          this.snackbarText = "Please Fill the death to preservation time",
          this.snackbar = true
      }
      else if (this.TissueData.tissue_expiry_date == "" || this.TissueData.tissue_expiry_date === null || this.TissueData.tissue_expiry_date == 'Invalid date') {
        this.errr3 = false
        return false,
          this.snackbarText = "Please Fill the Tissue expiry date",
          this.snackbar = true
      }
      // else if(this.TissueData.approved_usages == "" || this.TissueData.approved_usages == null){
      //   return false,
      //   this.snackbarText = "Please Fill the Approved usages",
      //   this.snackbar  =true
      // }
      // else if(this.TissueData.upload_konas_kss_img == "" || this.TissueData.upload_konas_kss_img == null){
      //   return false,
      //   this.snackbarText = "Please Fill the upload konas kss img",
      //   this.snackbar  =true
      // }
      // else if (this.TissueData.pachymetry == "" || this.TissueData.pachymetry == null) {
      //   this.errr4 = false
      //   return false,
      //     this.snackbarText = "Please Fill the pachymetry",
      //     this.snackbar = true
      // }
      else if (this.TissueData.specular_date_time2 == "" || this.TissueData.specular_date_time2 === null || this.TissueData.specular_date_time2 == 'Invalid date') {
        this.errr5 = false
        return false,
          this.snackbarText = "Please Fill the specular date time", 
          this.snackbar = true
      }
      // else if (this.TissueData.specular_tech == "" || this.TissueData.specular_tech == null) {
      //   this.errr6 = false
      //   return false,
      //     this.snackbarText = "Please Fill the specular tech",
      //     this.snackbar = true
      // }
      else if (this.TissueData.HBsAg == "" || this.TissueData.HBsAg == null) {
        this.errr7 = false
        return false,
          this.snackbarText = "Please Fill the HBsAg",
          this.snackbar = true
      }
      else if (this.TissueData.hiv_I_II == "" || this.TissueData.hiv_I_II == null) {
        this.errr8 = false
        return false,
          this.snackbarText = "Please Fill the HIV 1/2",
          this.snackbar = true
      }
      else if (this.TissueData.Syphilis == "" || this.TissueData.Syphilis == null) {
        this.errr9 = false
        return false,
          this.snackbarText = "Please Fill the Syphilis",
          this.snackbar = true
      }
      else if (this.TissueData.review_and_released_for_transplant_based_on == "" || this.TissueData.review_and_released_for_transplant_based_on == null) {
        this.errr10 = false
        return false,
          this.snackbarText = "Please Fill the This tissue was reviewed and released for Transplant based on the following:",
          this.snackbar = true
      }
      else if (this.TissueData.final_release_by == "" || this.TissueData.final_release_by == null) {
        this.errr11 = false
        return false,
          this.snackbarText = "Please Fill the final release by",
          this.snackbar = true
      }
      else if (this.TissueData.final_release_date_time == "" || this.TissueData.final_release_date_time == null || this.TissueData.final_release_date_time == 'Invalid date') {
        this.errr12 = false
        return false,
          this.snackbarText = "Please Fill the final release date time",
          this.snackbar = true
      }
      else if (this.TissueData.registered_with_HOTA == "" || this.TissueData.registered_with_HOTA == null) {
        this.errr13 = false
        return false,
          this.snackbarText = "Please Fill the Registered with HOTA",
          this.snackbar = true
      } else if (this.TissueData.history_of_hcv == "" || this.TissueData.history_of_hcv == null) {
        this.errr14 = false
        return false,
          this.snackbarText = "Please Fill the HCV",
          this.snackbar = true
      } else {
        this.errr = true
        this.errr1 = true
        this.errr2 = true
        this.errr3 = true
        this.errr4 = true
        this.errr5 = true
        this.errr6 = true
        this.errr7 = true
        this.errr8 = true
        this.errr9 = true
        this.errr10 = true
        this.errr11 = true
        this.errr12 = true
        this.errr13 = true
        this.errr14 = true
      }
      this.addTissueDetails()

      // if(this.formtype.view != '0' && this.formtype.view != null){
      //    this.$router.push({name:'TissueOfferedMain'});
      // }
    },

    HBsAgSelect(type) {
      this.TissueData.HBsAg = type
    },

    HcvSelect(type) {
      this.TissueData.history_of_hcv = type
    },

    hiv_I_II_Select(type) {
      this.TissueData.hiv_I_II = type
    },

    SyphilisSelect(type) {
      this.TissueData.Syphilis = type
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 9) {
        $event.preventDefault()
      }
    },

    onFileChange() {
      this.uploadKonasKssImgObj = URL.createObjectURL(this.uploadKonasKssImg)
      const reader = new FileReader();
      reader.readAsDataURL(this.uploadKonasKssImg);
      reader.onload = (e) => {
        this.TissueData.upload_konas_kss_img = e.target.result;
      };
    },

    async Tissue_Detail() {
      const DonorId = this.$route.params.donor_id
      const service = new TissueService()
      let response = await service.TissueDetail_od(DonorId)
      this.TissueData = response
      // console.log("hello",response.data)
      var valuee = this.TissueData.approved_usages.replace(/[\]\["*]/g, '')
      this.TissueData.approved_usages = valuee
    },

    async getdonorList() {
      this.listLoading = true
      const service = new donorService()
      let response = await service.donorList()
      if (response) {
        this.permissionData = response.permissions
        var formData = this.permissionData[8]
        this.formtype = this.permissionData[9]
        if (formData.view == "1" && formData.edit == null || formData.view == "1" && formData.edit == "0") {
          this.readOnlyField1 = true
          this.saveBtn = false
        }
      } else {
        this.totallist = 0
      }
      this.listLoading = false
    },

    async addTissueDetails() {

      const donorid = this.$route.params.donor_id;
      const data = {
        tissue_id: this.TissueData.tissue_id,
        OD_and_OS: 'OD',
        donor_id: donorid,
        approved_usages: this.TissueData.approved_usages,
        ebsr_donor_id: this.TissueData.ebsr_donor_id,
        death_to_preservation_time: this.TissueData.death_to_preservation_time,
        tissue_type: this.TissueData.tissue_type,
        //  ocular_cooling:this.TissueData.ocular_cooling,
        //  total:this.TissueData.total,
        tissue_expiry_date: moment.utc(this.TissueData.tissue_expiry_date).format("YYYY-MM-DD HH:mm"),
        upload_konas_kss_img: this.TissueData.upload_konas_kss_img,
        //  of_field,
        pachymetry: this.TissueData.pachymetry,
        //  specular_date_time2:this.TissueData.specular_date_time2,
        //  site_lamp_date:this.TissueData.site_lamp_date,
        site_lamp_tech: this.TissueData.site_lamp_tech,
        specular_tech: this.TissueData.specular_tech,
        HBsAg: this.TissueData.HBsAg,
        hiv_I_II: this.TissueData.hiv_I_II,
        Syphilis: this.TissueData.Syphilis,
        review_and_released_for_transplant_based_on: this.TissueData.review_and_released_for_transplant_based_on,
        final_release_by: this.TissueData.final_release_by,
        final_release_date_time: moment.utc(this.TissueData.final_release_date_time).format('YYYY-MM-DD HH:mm'),
        registered_with_HOTA: this.TissueData.registered_with_HOTA,
        //  tissue_disposition_outcome:this.TissueData.tissue_disposition_outcome,
        //  tissue_disposition_sub_outcome:this.TissueData.tissue_disposition_sub_outcome,
        //  reason_not_released_or_transplanted:this.TissueData.reason_not_released_or_transplanted
        history_of_hcv: this.TissueData.history_of_hcv

      };
      try {
        const response = await axios.post(
          `${baseUrl}tissueDetails/add`,
          data,
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status == 200) {
          this.snackbarText = response.data.msg;
          this.Tissue_Detail()
        } else {
          this.snackbarText = response.data.msg;
        }
        this.snackbar = true;
      } catch (e) {
        console.log(e);
      }
    },

  }
}
</script>

